import React, { Component } from 'react'
import ThreedotsMenu from '../components/ThreedotsMenu'

function Test() {
  return (
    <div>
      <ThreedotsMenu questionId={0} type={0} />
    </div>
  )
}

export default Test
